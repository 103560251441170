import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=74ce85af&scoped=true"
import script from "./UserProfile.vue?vue&type=script&lang=js"
export * from "./UserProfile.vue?vue&type=script&lang=js"
import style0 from "./UserProfile.vue?vue&type=style&index=0&id=74ce85af&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74ce85af",
  null
  
)

export default component.exports